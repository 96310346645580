<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="">
                        <div class="">
                          <div class="row">
                            <div class="col-xl-12">
                              <div class="product-detail">
                                <div class="row">
                                  <div class="col-md-12 col-2">
                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product1"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1572491176_2.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product2"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1572491182_%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product3"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1572491187_5.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product4"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1572491191_3.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product5"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1572491195_4.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="wehed()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1572491176_2.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="thnine()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1572491182_%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="tletha()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1572491187_5.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="arb3a()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1572491191_3.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="khamssa()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1572491195_4.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- end product img -->
                            </div>
                          </div>
                          <!-- end row -->
                        </div>
                      </div>
                      <!-- end card -->
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="mt-1 col-11">
                    <div class="row">
                      <h1 class="font-size-48 mb-3">
                        <strong>
                          Vision glue potting machine PGV501
                        </strong>
                      </h1>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Brand :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >D&H</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Model :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >PGV501</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Applications :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >The electronics, LED, crafts and where glue dispense demanded products.</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Suitable Material :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >AB crystal glue: Glue dropping application and potting application. AB glue potting for isolation (with fillers): Two components silicone , two parts of PU, AB glue epoxy and so on .</span
                        >
                      </h5>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 450px; height: 250px"
                            title="YouToube Video" 
                            src="https://www.youtube.com/embed/uZpUH1FAhx0" 
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <h1 style="color: black; font-weight: 700">Features</h1>
                        <br />
                        <div class="row" style="font-size: 14px">
                          1、Ultra - high precision double cylinder plunger pump, glue stable and easy to use. Daheng core patent plunger pump technology, with unique, innovative, practical, advanced; A new generation of fluid measurement ratio control with high accuracy, variable, uninterrupted and long life is realized.
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          2、The machine has automatic glue dispensing function, can match 1:1 to 10:1 within the two-component glue, can automatically match, automatic mixing, automatic mixing, automatic completion of the machine, do not need manual matching, is an online type of glue dispensing, to fill the amount of glue, how much glue dispensing, dispensing good glue is immediately to the product .
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          3、High-precision CCD vision capture and positioning, high-precision imported servo screw three-coordinate linear manipulator mobile device, to achieve high-precision positioning control, accuracy ±0.03mm
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          4、Motion control card: high-speed dot and line drawing and various irregular motion control; Teaching programming is adopted . 
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          5、Various of needles：glue mixing and dispensing evenly . 
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          6、Alarm function: the drums of A and B are equipped with alarm and induction devices of lower position
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          7、Vacuum feeding: both barrel A and barrel B can automatically vacuum feeding, which is helpful to eliminate air bubbles
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          8、Diaphragm pump feeding: A, B drums can automatically feed diaphragm pump
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          9、Automatic cleaning: the mixed part of AB adhesive is automatically cleaned with gas and liquid .
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          10、Anti-curing procedure, the machine can set the glue interval time according to the time of glue curing, to prevent the glue curing caused by the occasional stop during the working time, causing the machine to block up.
                        </div>
                        <br />
                      </div>
                      <!-- end col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <br /><br />

            <br />
            <b-tabs pills card style="font-size: 18px">
              <b-tab active title="Technical Data">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <img src="../../../../assets/images/product/main/production_equipement/Glue_potting_Machine/151.jpg" alt="">
                    <img src="../../../../assets/images/product/main/production_equipement/Glue_potting_Machine/152.jpg" alt="">
                 
                  </div>
                </div>
              </b-tab>
              <b-tab  title="The Working Principle">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    Liquid A & B are kept in two tanks separately. There are two sets of metering pumps inside the machine, draining out liquids individually from tank A & B. Liquid A & B mix with each other in the mixer pipe and dispensed out according to the programmable data.
                    <img
                      src="https://v4-upload.goalsites.com/141/editor_1529630830_%E5%9B%BE%E7%89%871.png"
                      alt="img-1"
                      class="img-fluid mx-auto d-block rounded"
                    />
                  </div>
                </div>
              </b-tab>
              <b-tab  title="Other Information">
               PGV glue potting machines are the fully automatic glue potting machine launched by Daheng automation. This machine is aiming at epoxy resin , polyurethane, silicone and other two-component materials for materials mixing and automatic perfusion. The equipment has the characteristics of accurate proportioning and quantitative consistency ,which can improve the quality and output of production . It can be equipped with dual-liquid mixing equipment to improve the efficiency of perfusion . In addition, it can be equipped with auxiliary equipment such as assembly line , baking path and multi -station rotating platform .
              </b-tab>
            </b-tabs>
            <br /><br />
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  data() {
    return {
      product1: true,
      product2: false,
      product3: false,
      product4: false,
      product5: false,
    };
  },
  components: {
    Header,

    Footer,
  },
  methods: {
    wehed() {
      (this.product1 = true),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = false),
        (this.product5 = false);
    },
    thnine() {
      (this.product1 = false),
        (this.product2 = true),
        (this.product3 = false),
        (this.product4 = false);
      this.product5 = false;
    },
    tletha() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = true),
        (this.product4 = false);
      this.product5 = false;
    },
    arb3a() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = true);
      this.product5 = false;
    },
    khamssa() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = false);
      this.product5 = true;
    },

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>